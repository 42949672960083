<template>
 <div :class="windowClass">
   <v-container>
    <v-row>
      <v-col cols="12">
                <v-card>
               <v-toolbar>
                 <v-icon left color="error">mdi-cancel</v-icon> Void Tickets
                 <v-spacer/>
                 
                 <v-divider vertical inset class="ml-5 mr-2"/>
                        <v-btn text small color="success"  @click="state='new',form=true"><v-icon small left color="success">mdi-plus</v-icon>  Add Ticket</v-btn>
               </v-toolbar>
               <v-card-text>
                <v-data-table
                    class="va-table"
                    dense
                    :headers="headers"
                    :items="voids"
                    >
                    <template v-slot:body="{ items }">
                        <tbody>
                        <tr v-for="(item,index) in items" :key="index">
                            <td>
                            {{ item.ticket_id }}</td>
                            <td>{{ item.void_date }}</td>
                            
                        </tr>
                        </tbody>
                    </template>
                    </v-data-table>
               </v-card-text>
             </v-card>
          </v-col>
    </v-row>
    </v-container>
    <va-void-form :show="form" @DialogEvent="afEvent" />
  </div>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
        form: false,
        areaform: false,
        state: 'new',
        game: {},
        voids:[],
        headers: [
            { text: 'Ticket No',},
            { text: 'Void Date'}
        ],
    }),
    beforeCreate: function () {
      if (!this.$session.exists()) {
        this.$router.push('/')
      }
    },
    created() {
       if (this.$session.exists()) {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get('jwt')
          this.setLoggedIn(true) 
         this.getVoids()
        }
    },
    computed:{
       windowClass() {
        if(this.$store.state.responsive){
          return "va-reponsive"
        } else {
          return "va-not-reponsive"
        }
      },
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn']),
      afEvent() {
        this.form = false
        this.getVoids()
      },
      getVoids(){
           this.$http.get("ticket/get_voids",).then(response => {
            response.data.voids != null?this.voids = response.data.voids:this.voids =[]
            console.log(this.voids)
          }).catch(e => {
            console.log(e.data)
          })
      },
      
     
    }
  }
</script>